.s-cms-content {
  @include remove-last-child-margin();

  ul {
    @include bullet-list();
  }

  h4 {
    @include title-sub();
  }

  .o-ratio {
    margin-bottom: spacer(1);
  }

  a {
    text-decoration: underline;
  }
}
