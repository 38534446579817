@include generate-responsive('.u-float-left') {
  float: left !important;
};

@include generate-responsive('.u-float-right') {
  float: right !important;
};

@include generate-responsive('.u-float-none') {
  float: none !important;
};
