.c-site-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: z(8);
  transition: $site-nav-transition;
  display: flex;
  align-items: center;
}

.c-site-nav__logo {
  display: block;
  height: $site-nav-height--mobile;
  width: $site-nav-height--mobile;
  background: #e1e5e2;
  display: block;
  z-index: z(2);

  @include mq(md) {
    height: $site-nav-height;
    width: $site-nav-height;
  }

  > img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.c-site-nav__trigger {
  margin-left: auto;
  display: inline-flex;
  height: 60px;
  width: 60px;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: z(8);
  background: rgba(#000, 0.4);

  .is-nav-open & {
    background: none;
  }
}

.c-site-nav__trigger-icon {
  @include burger(25px, 3px, 7px, #fff);

  .background--light & {
    @include burger-parts {
      background-color: #000;
    }
  }

  .c-site-nav__trigger.is-active & {
    @include burger-to-cross(#fff);
  }
}

.c-site-nav__list-wrap {
  width: $site-nav-list-width;
  height: 100vh;
  background: #222;
  position: fixed;
  top: 0;
  right: 0;
  z-index: z(1);
  transition: $site-nav-transition;
  transform: translateX(100%);
  pointer-events: none;

  .is-nav-open & {
    transform: none;
    pointer-events: all;
  }
}

.c-site-nav__inner {
  height: 100%;
  padding: 50px;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
}

.c-site-nav__content {
  margin: auto;
  margin-right: 0;
  width: 100%;
  text-align: right;
  transition: $site-nav-transition;
  transform: translateX(100%);

  .is-nav-open & {
    transition-delay: 0.05s;
    transform: none;
  }

  > * + * {
    margin-top: 25px;
  }
}

.c-site-nav__list {
  @include list-bare;
  width: 100%;
  padding-bottom: 25px;
  border-bottom: solid 2px rgba(#fff, 0.25);

  a {
    @include fluid-font(30px 34px);
    line-height: 1.3;
    @include font-family($weight: bold);
    text-transform: lowercase;
    color: #eee;

    &:hover {
      opacity: 0.7;
    }
  }
}

.c-site-nav__secondary {
  margin-top: spacer(1);
  display: block;
  @include font-family($weight: bold);
}

.c-site-nav__btn {
  font-size: 17px;
  margin-right: 0;
}

.c-site-nav__social {
  @include list-bare;
  margin-bottom: 0;

  li {
    display: inline-block;
  }

  a {
    font-size: 20px;
    color: #eee;
    display: flex;
    width: 35px;
    height: 35px;
    align-items: center;
    justify-content: center;

    &:hover {
      color: #fff;
    }
  }
}
