.c-portfolio__description {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: spacer(1);
  align-items: center;

  @include mq(lg) {
    flex-wrap: nowrap;
  }
}

.c-portfolio__title-wrap {
  flex: 1 1 auto;
  margin-bottom: spacer(1);

  // prevents a pixel from the next slide showing in the current one
  padding-left: 2px;

  @include mq(lg) {
    flex: 0 1 30%;
    margin-bottom: 0;
  }
}
.c-portfolio__text {
  flex: 1 1 auto;

  @include mq(lg) {
    flex: 1 1 70%;
    padding-left: 50px;
  }
}

.c-portfolio__title {
  margin-bottom: 0;
}

.c-portolio__img {
  display: block;
  width: 100%;
}

.c-portfolio-slider {
  margin-bottom: spacer(1);
  position: relative;

  .c-content-slider__arrow {
    color: #fff;
    bottom: -55px;
    top: auto;

    @include mq(md) {
      display: none;
    }

    &.swiper-button-disabled {
      opacity: 0.5;
    }

    &:before {
      font-size: 70%;
    }
  }
}

.c-portfolio-thumbs {
  padding-left: 60px;
  padding-right: 60px;
  position: relative;

  @include mq($until: md) {
    display: none;
  }

  .swiper-slide {
    cursor: pointer;

    img {
      display: block;
      border: solid 3px transparent;
      transition: 0.25s ease-in;
    }

    &.swiper-slide-active img {
      border: solid 3px #fff;
    }
  }
}

.c-portfolio-thumbs__arrow {
  color: #fff;
}
