.c-box {
  flex: 1 1 100%;
  text-align: center;
  max-width: 350px;
  background-color: #333;

  @include mq(md) {
    max-width: none;
    min-height: 330px;
  }
}

.c-box__img {
  margin-bottom: spacer(0.75);
}

.c-box__content {
  @include remove-last-child-margin();
  color: #000;
  font-size: 12px;
  line-height: 1.4;
  padding: spacer(1);
  padding-top: spacer(1.5);

  a {
    color: inherit;
    text-decoration: underline;
  }
}

.c-box__title {
  font-weight: 900;
  margin-bottom: spacer(0.75);
}
