// ====================================
// BLOCK
// ====================================

/**
 * Stacked image and text object
**/

// Set the names and values of the spacing modifier classes
$block-spacing: (
  null: spacer(1),
  "--flush": 0
);

.o-block,
.o-block__img {
  display: block;
}

// Size modifiers
// ====================================

// Loop through the settings map and generate spacing modifier classes
@each $block-modifier, $block-value in $block-spacing {
  .o-block__img {
    .o-block#{$block-modifier} > & {
      margin-bottom: $block-value;
    }
  }
}

// Alignment modifiers
// ====================================

.o-block--center {
  text-align: center;
}

.o-block--right {
  text-align: right;
}
