.u-color-inherit {
  color: inherit !important;
}

.u-color-white {
  color: #fff !important;
}

.u-color-black {
  color: #000 !important;
}

.u-bg-inherit {
  background-color: inherit !important;
}

.u-bg-white {
  background-color: #fff !important;
}

.u-bg-black {
  background-color: #000 !important;
}

.u-bg-transparent {
  background-color: transparent !important;
}

// Loop through the $ui-colors map in 'Settings -> Colors'
// and spit out our color utility classes

@each $color-name, $color-map in $palette {
  @if type-of($color-map) == "color" {
    .u-color-#{$color-name} {
      color: $color-map !important;
    }

    .u-bg-#{$color-name} {
      background-color: $color-map !important;
    }
  }

  @else {
    @each $color-alias, $color-value in $color-map {
      $alias: "-#{$color-alias}";

      // Color utility classes
      .u-color-#{$color-name}#{$alias} {
        color: $color-value !important;
      }

      // Background background utility classes
      .u-bg-#{$color-name}#{$alias} {
        background-color: $color-value !important;
      }
    }
  }
}

@each $grayscale-name, $grayscale-value in $grayscale {
  // Grayscale color utility classes
  .u-gray-#{$grayscale-name} {
    color: $grayscale-value !important;
  }

  .u-bg-gray-#{$grayscale-name} {
    background-color: $grayscale-value !important;
  }
}
