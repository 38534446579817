.c-article {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-top: $site-nav-height--mobile + spacer(1);
  padding-bottom: spacer(2);
  color: #fff;
  margin-left: -25px;

  @include mq(md) {
    padding-top: $site-nav-height + spacer(1);
    padding-bottom: spacer(3);
  }

  @include mq(lg) {
    justify-content: space-between;
  }

  &__featured {
    width: 138px;

    @include mq($until: lg) {
      display: none;
    }
  }

  &__sidebar,
  &__content {
    padding-left: 25px;
    flex: 1 1 100%;
  }

  &__sidebar {
    @include mq(lg) {
      flex-basis: 40%;
      padding-top: spacer(2);
    }
  }

  &__content {
    @include mq(lg) {
      flex-basis: 56%;
    }
  }

  &__title a {
    display: block;
    position: relative;
    color: inherit;
    padding-left: 30px;

    @include mq(md) {
      padding-left: 0;
    }

    &::before {
      @include fluid-font(16px 20px);
      content: "";
      width: 1em;
      height: 1em;
      position: absolute;
      top: 50%;
      margin-top: -0.5em;
      border-left: solid 5px #fff;
      border-bottom: solid 5px #fff;
      transform: rotate(45deg);
      transition: 0.2s;
      left: 0;

      @include mq(md) {
        opacity: 0;
        left: -30px;
      }
    }

    &:hover {
      transform: translateX(30px);

      &::before {
        opacity: 1;
      }
    }
  }
}

.c-article__nav {
  @include fluid-font(14px 16px);
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  line-height: 1.3;

  &-item {
    flex: 1 1 100%;
    padding-left: spacer(1);
    max-width: 250px;
  }
}
