// ====================================
// COLORS
// ====================================


// Palette
// ====================================

// Define all the project colors here.
// Do not use these values directly in your Sass files. Use the more
// specifically named variables below that use these root values.

$palette: (
  base-black: #000,
  base-white: #fff
);

// Grayscale
// ====================================

// Grayscale palette from lightest to darkest

$grayscale: (
  10 : shade( palette(base-white), 10% ),
  30 : shade( palette(base-white), 30% ),
  64 : shade( palette(base-white), 64% ),
);

// UI Colors
// ====================================

// Here we take the literal colors from our palette and define them
// against variables that we can utilise anywhere throughout the project.

$ui-colors: (

  // Page
  // ----------------------------------

  page: (
    background: #fff,
    text: gray(64)
  ),

  // Links
  // ----------------------------------

  link: (
    base: #eee,
    hover: #fff
  ),

  // Buttons
  // ----------------------------------

  button: (
    base: (
      background: rgba(#fff, 0.15),
      border: #fff,
      color: #fff,

      hover: (
        background: rgba(#fff, 0.3),
        border: #fff,
        color: #fff,
      )
    ),
  ),

  // Borders
  // ----------------------------------

  border: gray(10)

);

