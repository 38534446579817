@mixin font-family (
  $weight: null,
  $family: base,
  $settings: $global-font
) {

  font-family: map-fetch($settings, family, $family, name);

  @if $weight {
     font-weight: map-fetch($settings, family, $family, weight, $weight);
  }
}
