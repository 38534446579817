// Return a unitless number
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

// Convert number to rem. Based on:
// https://github.com/davidtheclark/scut/blob/master/src/functions/_rem.scss
@function to-rem($pixels) {
  $rem-vals: ();

  @each $val in $pixels {
    $val-in-rems: strip-unit($val) / strip-unit($global-font-size) * 1rem;
    $rem-vals: append($rem-vals, $val-in-rems);
  }

  @if length($rem-vals) == 1 {
    // return a single value instead of a list,
    // so it can be used in calculations
    @return nth($rem-vals, 1);
  }

  @else {
    @return $rem-vals;
  }
}

// Spacing helper function
@function spacer($value) {
  // if value is different than a multiple of 0.25
  @if $value % 0.25 != 0 {
    @warn "Spacer value must be a multiple of 0.25";

    @return "Spacer value must be a multiple of 0.25";
  }

  @return round($global-spacing-unit * $value);
}

// Map extend
@function map-extend($map, $maps...) {
  $max: length($maps);

  @for $i from 1 through $max {
    $current: nth($maps, $i);

    @each $key, $value in $current {
      @if type-of($value) == "map" and type-of(map-get($map, $key)) == "map" {
        $value: map-extend(map-get($map, $key), $value);
      }

      $map: map-merge($map, ($key: $value));
    }
  }

  @return $map;
}

// Get values even from nested maps
@function map-fetch($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);

    @if not $map {
      @error "The value doesn't exist in the map.";
    }
  }

  @return $map;
}

// Check if map has nested keys
@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }

    $map: map-get($map, $key);
  }

  @return true;
}

// Lighten a color
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

// Darken a color
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

// Colors helper functions

@function get-color($map, $color, $shade, $alpha) {
  @if map-has-key($map, $color) {
    $color-val: null;

    @if type-of(map-fetch($map, $color)) == "map" {
      $color-val: map-fetch($map, $color, $shade);
    }

    @else {
      $color-val: map-fetch($map, $color);
    }

    @return rgba($color-val, $alpha);
  }

  @else {
    @warn "Color '#{$color}' was not found in map.";
  }
}

@function palette($keys...) {
  @if not map-has-nested-keys($palette, $keys...) {
    @warn "No layer found in $palette map.";
  }

  @return map-fetch($palette, $keys...);
}

@function color($keys...) {
  @if not map-has-nested-keys($ui-colors, $keys...) {
    @warn "No layer found in $ui-colors map.";
  }

  @return map-fetch($ui-colors, $keys...);
}

@function white($alpha: 1) {
  @return rgba(#fff, $alpha);
}

@function black($alpha: 1) {
  @return rgba(#000, $alpha);
}

// Grayscale helper function
@function gray($gradation, $alpha: 1) {
  @if map-has-key($grayscale, $gradation) {
    $grayscale-val: map-get($grayscale, $gradation);

    @return rgba($grayscale-val, $alpha);
  }

  @else {
    @warn "No gradation of #{$gradation} found in $grayscale map.";
  }
}

// Z-index helper function

@function z($value) {
  @if not map-has-key($global-z-index, $value) {
    @warn "No layer found in $global-z-index map.";
  }

  @return map-fetch($global-z-index, $value);
}


