// Alignment

@include generate-responsive('.u-text-center') {
  text-align: center !important;
};

@include generate-responsive('.u-text-right') {
  text-align: right !important;
};

@include generate-responsive('.u-text-left') {
  text-align: left !important;
};

// Style

@include generate-responsive('.u-text-italic') {
  font-style: italic !important;
};

@include generate-responsive('.u-text-uppercase') {
  text-transform: uppercase !important;
};

// Weight

@include generate-responsive('.u-text-normal') {
  font-weight: normal !important;
};

@include generate-responsive('.u-text-bold') {
  font-weight: bold !important;
};

// White space

@include generate-responsive('.u-text-nowrap') {
   white-space: nowrap !important;
};
