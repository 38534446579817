$spacing-directions: (
  null: null,
  't': '-top',
  'r': '-right',
  'b': '-bottom',
  'l': '-left',
  'h': '-left' '-right',
  'v': '-top' '-bottom'
);

$spacing-properties: (
  margin: 'm',
  padding: 'p'
);

$spacing-sizes: (
   null: spacer(1),
  '-0': 0,
  '-s': spacer(0.5),
  '-l': spacer(1.5),
  '-xl': spacer(2),
  '-xxl': spacer(4)
);

@include generate-responsive {
  @each $direction-alias, $direction-name in $spacing-directions {
    @each $size-alias, $size-value in $spacing-sizes {
      @each $property-name, $property-alias in $spacing-properties {
        .u-#{$property-alias}#{$direction-alias}#{$size-alias}#{$_responsive-alias} {
          @each $direction-name-value in $direction-name {
            #{$property-name}#{$direction-name-value}: $size-value !important;
          }
        }
      }
    }
  }
}
