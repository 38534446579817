// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

$wp-breakpoint: "sm";

// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}

.aligncenter {
  display: block;
  margin: spacer(1) auto;
  height: auto;
}

.alignleft,
.alignright {
  margin-bottom: spacer(1);
  height: auto;
}

@include mq($wp-breakpoint) {
  .alignleft {
    float: left;
    margin-right: spacer(1);
  }

  .alignright {
    float: right;
    margin-left: spacer(1);
  }
}

// Captions
.wp-caption {
  margin-bottom: spacer(0.5);
}

.wp-caption-text {
  font-size: 90%;
}

// Text meant only for screen readers
.screen-reader-text {
  @include scut-hide-visually();
}

// Responsive WP videos

.wp-video {
  margin-bottom: spacer(1);
}

.wp-video,
video.wp-video-shortcode,
.mejs-container,
.mejs-overlay.load {
  width: 100% !important;
  height: 100% !important;
}

.mejs-container {
  padding-top: 9 / 16 * 100%;
}

.wp-video,
video.wp-video-shortcode {
  max-width: 100% !important;
}

video.wp-video-shortcode {
  position: relative;
}

.mejs-mediaelement {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}


