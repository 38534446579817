// Based on https://github.com/inuitcss/inuitcss/blob/develop/objects/_objects.ratio.scss

$ratios: (
  "--5\\:4": (5:4),
  "--4\\:3": (4:3),
  "--16\\:9": (16:9)
);

.o-ratio {
  position: relative;
  display: block;
  padding-bottom: 100%;

  &:before {
    content: "";
    display: block;
    width: 100%;
  }
}

.o-ratio__content,
.o-ratio > iframe,
.o-ratio > embed,
.o-ratio > object {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

@include generate-responsive {
  @each $ratio-name, $ratio-value in $ratios {
    @each $antecedent, $consequent in $ratio-value {
      @if (type-of($antecedent) != number) {
        @error "`#{$antecedent}` needs to be a number.";
      }

      @if (type-of($consequent) != number) {
        @error "`#{$consequent}` needs to be a number.";
      }

      .o-ratio#{$ratio-name}#{$_responsive-alias}:before {
        padding-bottom: ($consequent/$antecedent) * 100%;
      }
    }
  }
}
