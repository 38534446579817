.c-intro-content {
  text-align: center;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}


.c-intro__title {
  @include fluid-font(22px 55px);
  line-height: 1;
  margin-bottom: 0;

  span {
    color: #ee2e24;
    vertical-align: top;
    display: inline-block;
  }
}

.c-intro__title--lg {
  @include fluid-font(40px 84px);
}


.c-intro-anchor {
  background-color: transparent;
  display: block;
}

.c-intro-anchor__line {
  display: block;
  width: 75px;
  height: 3px;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -38px;
}

.c-intro-bottom {
  @include remove-last-child-margin;
  @include font-family($weight: black);

  font-size: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: -20px;
}
