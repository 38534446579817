@mixin corner-item(
  $position,
  $size-sm: 20px,
  $size-md: 25px,
  $size: 30px,
  $color: #fff,
  $width: 2px
) {
  content: "";
  position: absolute;
  display: block;
  width: $size-sm;
  height: $size-sm;

  @include mq(sm) {
    width: $size-md;
    height: $size-md;
  }

  @include mq(md) {
    width: $size;
    height: $size;
  }

  @if $position == "top-left" {
    left: 0;
    top: 0;
    border-left: solid $width $color;
    border-top: solid $width $color;
  }

  @if $position == "top-right" {
    right: 0;
    top: 0;
    border-right: solid $width $color;
    border-top: solid $width $color;
  }

  @if $position == "bottom-left" {
    left: 0;
    bottom: 0;
    border-left: solid $width $color;
    border-bottom: solid $width $color;
  }

  @if $position == "bottom-right" {
    right: 0;
    bottom: 0;
    border-right: solid $width $color;
    border-bottom: solid $width $color;
  }
}

.o-corners {
  position: relative;
}

.o-corners__top-left {
  @include corner-item("top-left");
}

.o-corners__top-right {
  @include corner-item("top-right");
}

.o-corners__bottom-left {
  @include corner-item("bottom-left");
}

.o-corners__bottom-right {
  @include corner-item("bottom-right");
}
