// ====================================
// GLOBAL
// ====================================
// Project wide variables

// Spacing
// ====================================

$global-spacing-unit: 25px;

// Fonts
// ====================================

$global-font: (

  family: (
    base: (
      name: ("proxima-nova", sans-serif),
      weight: (
        regular: 400,
        bold: 700,
        black: 900
      )
    )
  ),

  line-height: (
    base: 1.5,
    small: 1.2,
    tiny: 1.1
  )
);

// Breakpoints
// ====================================

$global-breakpoints: (
  sm : 420px,
  md : 770px,
  lg : 980px,
  xl : 1300px
);

// Reassign MQ breakpoints map to use our global settings map
$mq-breakpoints: $global-breakpoints;

// Z-index
// ====================================

$global-z-index: (
  0: 0,
  1: 10,
  2: 20,
  3: 30,
  4: 40,
  5: 50,
  6: 60,
  7: 70,
  8: 80,
  9: 90,
  10: 100
);

// Site Nav
// ====================================

$site-nav-height: 100px;
$site-nav-height--mobile: 65px;
$site-nav-list-width: 300px;

$site-nav-transition-easing: ease-out;
$site-nav-transition-length: 0.35s;
$site-nav-transition: $site-nav-transition-length $site-nav-transition-easing;

// Sections
// ====================================

$section-anchor-height: 60px;
$section-bp: 900px;

