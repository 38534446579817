address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
    margin-bottom: spacer(1);
}

dd, ol, ul {
    margin-left: spacer(1);
}