@include generate-responsive('.u-block') {
  display: block !important;
};

@include generate-responsive('.u-inline-block') {
  display: inline-block !important;
};

@include generate-responsive('.u-inline') {
  display: inline !important;
};

@include generate-responsive('.u-hidden') {
  display: none !important;
};

@include generate-responsive('.u-hidden-visually') {
  @include scut-hide-visually;
};
