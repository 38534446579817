// ====================================
// CONTAINER
// ====================================

/**
 * Content constraining wrapping element
**/

.o-container {
  @include scut-clearfix;
  max-width: 1150px;
  margin-left: auto;
  margin-right: auto;
  padding-left: spacer(0.5);
  padding-right: spacer(0.5);

  @include mq(md) {
    padding-left: spacer(1);
    padding-right: spacer(1);
  }
}

.o-container--narrow {
  max-width: 1000px;
}
