// ====================================
// BLOCK
// ====================================

/**
 * Simple blocked off content
**/


.o-box {
  @include scut-clearfix;
  @include remove-last-child-margin;

  display: block;
  padding: spacer(0.75) spacer(1);
}

