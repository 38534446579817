// ====================================
// Widths
// ====================================

// Set the fractions for the grid system.
//
// For example:
// .u-2-of-3
//
// N.B. For full width units use class .u-full-width
$widths: 2, 3, 4, 5;

// Enable of disable push-/pull- classes
//
// For example:
// .u-push-2-of-3
// .u-pull-2-of-3
$widths-offsets: 5;

// Set for which breakpoints to set the widths.
// Default value is 'all' - it refers to all breakpoint names defined
// in Settings -> Responsive
//
// For example:
// .u-2-of-3@tablet
$widths-breakpoints: "all";

@include generate-responsive($respond-to: $widths-breakpoints) {
  .u-full-width#{$_responsive-alias} {
    flex-basis: auto !important;
    width: 100% !important;
  }

  @each $denominator in $widths {
    @for $numerator from 1 through $denominator - 1 {
      .u-#{$numerator}-of-#{$denominator}#{$_responsive-alias} {
        flex-basis: auto !important;
        width: 100% * ($numerator/$denominator) !important;
      }

      @if $widths-offsets {
        .u-push-#{$numerator}-of-#{$denominator}#{$_responsive-alias} {
          position: relative !important;
          right: auto !important;
          left: 100% * ($numerator/$denominator) !important;
        }

        .u-pull-#{$numerator}-of-#{$denominator}#{$_responsive-alias} {
          position: relative !important;
          right: 100% * ($numerator/$denominator) !important;
          left: auto !important;
        }
      }
    }
  }
}
