// Style defining settings
$button-types: (
  base: (
    color: color(button, base, color),
    background: color(button, base, background),
    border: color(button, base, border),
    hover: (
      color: color(button, base, hover, color),
      background: color(button, base, hover, background),
      border: color(button, base, hover, border),
    )
  ),
  "dark": (
    color: color(button, base, color),
    background: rgba(#000, 0.2),
    border: color(button, base, border),
    hover: (
      color: color(button, base, hover, color),
      background: rgba(#000, 0.5),
      border: color(button, base, hover, border),
    )
  ),
);

@mixin btn-base {
  @include font-family($weight: black);
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  appearance: none;
  cursor: pointer;
  border: solid 2px;
  border-radius: 0;
  text-transform: uppercase;
  padding: 10px 30px;

  &,
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

@mixin btn-type($name: base, $map: $button-types) {
  $config: map-fetch($map, $name);

  color: map-fetch($config, color);
  background: map-fetch($config, background);
  border-color: map-fetch($config, border);

  &:hover,
  &:focus {
    color: map-fetch($config, hover, color);
    background: map-fetch($config, hover, background);
    border-color: map-fetch($config, hover, border);
  }
}

.c-btn {
  @include fluid-font(14px 17px);
  @include btn-base;
  margin-right: 10px;
  margin-bottom: 10px !important; // overwrites last-child-margin
}

// Color variations
// ====================================

@each $type-name, $type-map in $button-types {
  $modifier: "--#{$type-name}";

  @if $type-name == "base" {
    $modifier: null;
  }

  .c-btn#{$modifier} {
    @include btn-type(#{$type-name});
  }
}

// Size variations
// ====================================

.c-btn--full {
  display: block;
  width: 100%;
}
