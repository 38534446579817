// ====================================
// ICON
// ====================================

/**
 * Baseline code for svg icons
**/

@mixin icon {
  width: 1em;
  height: 1em;
  stroke: currentColor;
  fill: currentColor;
  display: inline-block;
  vertical-align: middle;
}

.o-icon {
  @include icon;
}
