.c-main {
  transition: $site-nav-transition;
  position: relative;
  overflow: hidden;
  z-index: z(1);

  .is-nav-open & {
    transform: translateX(-$site-nav-list-width);
  }
}
