.u-flex {
  display: flex !important;
}

.u-flex-inline {
  display: inline-flex !important;
}

.u-flex-row {
  flex-direction: row !important;
}

.u-flex-row-reverse {
  flex-direction: row-reverse !important;
}

.u-flex-col {
  flex-direction: column !important;
}

.u-flex-col-reverse {
  flex-direction: column-reverse !important;
}

.u-flex-wrap {
  flex-wrap: wrap !important;
}

.u-flex-nowrap {
  flex-wrap: nowrap !important;
}

.u-flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.u-flex-justify-start {
  justify-content: flex-start !important;
}

.u-flex-justify-end {
  justify-content: flex-end !important;
}

.u-flex-justify-center {
  justify-content: center !important;
}

.u-flex-justify-between {
  justify-content: space-between !important;
}

.u-flex-justify-around {
  justify-content: space-around !important;
}

.u-flex-align-items-start {
  align-items: flex-start !important;
}

.u-flex-align-items-end {
  align-items: flex-end !important;
}

.u-flex-align-items-center {
  align-items: center !important;
}

.u-flex-align-items-stretch {
  align-items: stretch !important;
}

.u-flex-align-items-baseline {
  align-items: baseline !important;
}

.u-flex-align-content-start {
  align-content: flex-start !important;
}

.u-flex-align-content-end {
  align-content: flex-end !important;
}

.u-flex-align-content-center {
  align-content: center !important;
}

.u-flex-align-content-stretch {
  align-content: stretch !important;
}

.u-flex-align-content-between {
  align-content: space-between !important;
}

.u-flex-align-content-around {
  align-content: space-around !important;
}

/**
 * 1. Set the flex-shrink default explicitly to fix IE10 - http://git.io/vllC7
 */


.u-flex > *,
.u-flex-inline > * {
  flex-shrink: 1; /* 1 */
}

.u-flex-align-self-start {
  align-self: flex-start !important;
}

.u-flex-align-self-end {
  align-self: flex-end !important;
}

.u-flex-align-self-center {
  align-self: center !important;
}

.u-flex-align-self-stretch {
  align-self: stretch !important;
}

.u-flex-align-self-baseline {
  align-self: baseline !important;
}

.u-flex-align-self-auto {
  align-self: auto !important;
}

.u-flex-order-first {
  order: -1 !important;
}

.u-flex-order-last {
  order: 1 !important;
}

.u-flex-order-none {
  order: 0 !important;
}

.u-flex-grow-1 {
  flex: 1 1 0% !important;
}

.u-flex-grow-2 {
  flex: 2 1 0% !important;
}

.u-flex-grow-3 {
  flex: 3 1 0% !important;
}

.u-flex-grow-4 {
  flex: 4 1 0% !important;
}

.u-flex-grow-5 {
  flex: 5 1 0% !important;
}

.u-flex-expand {
  margin: auto !important;
}

.u-flex-expand-left {
  margin-left: auto !important;
}

.u-flex-expand-right {
  margin-right: auto !important;
}

.u-flex-expand-top {
  margin-top: auto !important;
}

.u-flex-expand-bottom {
  margin-bottom: auto !important;
}