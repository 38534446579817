$clients-bp: 800px;

.c-clients-wrap {
  max-width: 900px;
  margin: 0 auto;
  @include mq($clients-bp) {
    padding: 0;
  }
}

.c-clients__slide {
  margin-bottom: 30px;

  @include mq(sm) {
    height: 100px;
    margin-bottom: 0;
  }

  @include mq($clients-bp) {
    height: 130px;
  }
}

.c-clients__item {
  max-width: 130px;
  margin: 0 auto;
  display: block;
  height: 100%;

  @include mq(sm) {
    max-width: 170px;
  }

  > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

a.c-clients__item {
  &:hover {
    transform: scale(1.05);
  }
}

.c-clients__arrow {
  @include mq($clients-bp) {
    display: none;
  }

  &.swiper-button-disabled {
    transition: 0.5s;
    opacity: 0;
  }
}
