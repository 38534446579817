$heading-sizes: (
  h1: (30px, 36px),
  h2: (25px, 30px),
  h3: (20px, 24px),
  h4: 18px,
  h5: 16px,
  h6: 14px
);

h1, h2, h3, h4, h5, h6 {
  line-height: map-fetch($global-font, line-height, tiny);
}

@each $heading, $heading-size in $heading-sizes {
  #{$heading} {
    @include fluid-font($heading-size);
  }
}
