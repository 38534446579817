.c-site-foot {
  background-color: #222;
  color: #fff;
  padding-top: $site-nav-height--mobile + spacer(1);
  padding-bottom: $site-nav-height--mobile + spacer(1);

  @include mq(md) {
    padding-top: $site-nav-height + spacer(1);
    padding-bottom: $site-nav-height + spacer(1);
  }
}

.c-site-foot__top {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.c-site-foot__title {
  margin-bottom: 0;
}

.c-site-foot__social {
  @include list-bare;
  margin-bottom: 0;
  margin-left: auto;

  li {
    display: inline-block;

    a {
      display: flex;
      width: 35px;
      height: 35px;
      justify-content: center;
      align-items: center;
    }
  }
}

.c-site-foot__content {
  display: flex;
  flex-direction: column;
}

.c-site-foot__contact {
  font-size: 17px;

  @include mq($until: md) {
    order: -1;
  }
}

.c-site-foot__contact-title {
  font-size: 19px;
  margin-bottom: spacer(0.25);
}

.c-site-foot__definition {
  font-size: 14px;
  margin-bottom: spacer(1);

  h3 {
    @include fluid-font(26px 32px);

    span {
      display: inline-block;
      vertical-align: middle;
      width: 0.3em;
      height: 0.3em;
      background-color: #fff;
      border-radius: 50%;
    }
  }

  h4 {
    font-size: 19px;
    font-style: italic;
    font-weight: normal;
    margin-bottom: spacer(0.5);
  }

  ol {
    margin-bottom: spacer(0.5);

    @include mq(sm) {
      padding-left: spacer(1);
    }
  }

  a {
    text-decoration: underline;
  }
}

.c-site-foot__address {
  @include list-bare;
  margin-bottom: 0;
  text-align: center;

  li {
    display: inline-block;

    + li {
      &:before {
        content: "";
        width: 0.3em;
        height: 0.3em;
        background: #fff;
        display: inline-block;
        margin-left: 15px;
        margin-right: 15px;
        vertical-align: middle;
        border-radius: 50%;
      }
    }

    @include mq($until: md) {
      &:first-child,
      &:last-child {
        display: block;
      }

      &:nth-child(2):before,
      &:last-child:before {
        display: none;
      }
    }
  }
}

.c-site-foot__form-wrap {
  margin-bottom: spacer(1.5);

  @include mq(md) {
    direction: rtl;
  }

  .gform_footer {
    @include mq(md) {
      text-align: right;
    }
  }

  .gform_ajax_spinner {
    display: none;
  }

  .validation_error {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: spacer(1);
  }

  .validation_message {
    font-size: 14px;
    margin-top: spacer(-0.75);
    margin-bottom: spacer(0.5);
    color: #E53935;
  }

  .gform_confirmation_message {
    direction: ltr;
  }

  .gfield_visibility_ {
    display: none;
  }

  form {
    max-width: 390px;
    direction: ltr;
    margin: 0 auto;

    @include mq(md) {
      margin: 0;
    }

    ul {
      @include list-bare;
    }

    input,
    label,
    textarea {
      width: 100%;
      display: block;
    }

    label {
      font-size: 16px;
      text-transform: lowercase;
    }

    input,
    textarea {
      @include font-family;
      font-size: 16px;
      border: solid 2px transparent;
      border-radius: 0;
      margin-bottom: spacer(1);
      background: #ddd;
      padding: 0 10px;
      transition: background 0.5s;

      &:focus {
        background: #fff;
        outline: none;
      }
    }

    .gfield_error {
      input,
      textarea {
        border-color: #E53935;
      }
    }

    input {
      height: 34px;
    }

    input[type="submit"] {
      @include btn-base;
      @include btn-type;
      height: auto;
      width: auto;
      margin-left: auto;
      margin-right: auto;
    }

    textarea {
      padding: 10px;
      min-height: 110px;
      resize: vertical;
    }
  }
}
