$card-corner-size: 25px;
$card-corner-border: solid 2px;
$card-corner-padding: 4%;

.c-card {
  display: block;
  position: relative;
  margin: 0 auto;
  max-width: 300px;
}

.c-card__img {
  display: block;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.c-card__overlay {
  @include cover;
  @include remove-last-child-margin;
  left: 0;
  top: 0;
  display: block;
  background-color: rgba(#000, 0.4);
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: z(1);

  @include mq(md) {
    opacity: 0;
    background-color: rgba(#000, 0.7);

    .c-card:hover & {
      opacity: 1;
    }
  }

  .c-card--alt & {
    @include mq($until: md) {
      height: auto;
      bottom: 0;
      top: auto;
      background-color: transparent;
      background-image: linear-gradient(to top, rgba(#000, 0.8), rgba(#000, 0));
    }
  }
}

.c-card__content {
  @include remove-last-child-margin;
  padding: spacer(1);
  text-shadow: 1px 1px 5px rgba(#000, 0.5);
  text-align: center;

  @include mq(md) {
    text-shadow: none;
  }

  a {
    display: block;
    margin-top: 0.5em;
    font-size: 0.8em;
    font-weight: bold;
  }
}

.c-card__title {
  @include font-family($weight: black);
  @include fluid-font(18px 28px);
  line-height: 1.2;
  text-align: center;
  margin-bottom: 0;
}

.c-card__title--small {
  @include fluid-font(18px 21px);

  @include mq($until: sm) {
    font-size: 24px;
  }
}

.c-card__title-sub {
  @include fluid-font(14px 17px);
  line-height: 1.1;
  font-weight: normal;
}

.c-card__corners {
  position: absolute;
  width: calc(100% - (#{$card-corner-padding}*2));
  height: calc(100% - (#{$card-corner-padding}*2));
  top: $card-corner-padding;
  left: $card-corner-padding;
  z-index: z(2);
  pointer-events: none;
}
