.c-fixed-bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: {
    color: #222;
    size: cover;
    position: center;
  }
}


.c-fixed-bg--overlay {
  &:before {
    @include cover;
    content: "";
    background: rgba(#000, 0.65);
  }
}
