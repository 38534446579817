// ====================================
// LAYOUT
// ====================================

/**
 * Grid-like layout system
**/

$layout-spacing: (
  null: spacer(1),
  '--flush': 0,
  '--large': spacer(1.5),
  '--huge': spacer(2)
);

@mixin layout-container {
  display: flex;
  flex-flow: row wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin layout-item {
  // 1. Fix issue where elements with overflow extend past the
  //    layout item container -
  //    https://weblog.west-wind.com/posts/2016/Feb/15/Flexbox-Containers-PRE-tags-and-managing-Overflow

  flex-basis: 100%;
  min-width: 0; // [1]
}

@mixin layout-size(
    $gutter: map-fetch($layout-spacing, null),
    $selector: ".o-layout__item",
    $vertical: false
) {
  margin-left: -$gutter;

  > #{$selector} {
    padding-left: $gutter;
  }

  @if $vertical {
    margin-top: -$gutter;

      > #{$selector} {
          padding-top: $gutter;
      }
    }
}

.o-layout {
  @include layout-container;
}

.o-layout__item {
  @include layout-item;
}

// Size modifiers
// ====================================

// Loop through the settings map and generate spacing modifier classes
@each $layout-modifier, $layout-value in $layout-spacing {
  .o-layout#{$layout-modifier} {
    @include layout-size($layout-value);
  }
}

// Vertical spacing
// ====================================

@each $layout-modifier, $layout-value in $layout-spacing {
  .o-layout--vertical {
    &.o-layout#{$layout-modifier} {
      @include layout-size($layout-value, $vertical: true);
    }
  }
}

// Vertical alignement
// ====================================

.o-layout--top {
  align-items: flex-start;
}

.o-layout--middle {
  align-items: center;
}

.o-layout--bottom {
  align-items: flex-end;
}

// Horizontal alignement
// ====================================

.o-layout--center {
  justify-content: center;
}

.o-layout--right {
  justify-content: flex-end;
}

// Auto width cells
// ====================================

/**
 *
 * 1. Provide all values to avoid IE10 bug with shorthand flex
 *    http://git.io/vllC7
 *
 *    Use `0%` to avoid bug in IE10/11 with unitless flex basis
 *    http://git.io/vllWx
 */

@include generate-responsive {
  .o-layout--auto#{$_responsive-alias} > .o-layout__item {
    flex: 1 1 0%; /* 1 */
  }
}


// Equal height cells
// ====================================

.o-layout--equal-height > .o-layout__item {
  display: flex;
}
