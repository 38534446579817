// ====================================
// LIST BARE
// ====================================

/**
 * Stripped down list
**/

@mixin list-bare {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}


.o-list-bare {
  @include list-bare();
}
