// ====================================
// MEDIA
// ====================================

/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
**/

// Set the names and values of the spacing modifier classes
$media-spacing: (
  null: spacer(1),
  '--flush': 0
);

@mixin media-container {
  display: flex;
  flex-wrap: nowrap;
}

@mixin media-section {
  flex: 0 1 auto;

  > img {
    max-width: none;
  }

  @include remove-last-child-margin;
}

@mixin media-size(
    $gutter: map-fetch($media-spacing, null),
    $selector: ".o-media__section"
) {
  > #{$selector} {

    &:first-child {
      padding-right: $gutter;
    }

    &:last-child:not(:nth-child(2)) {
      padding-left: $gutter;
    }
  }
}

// Stacked layout
// ====================================

// Use with max-width media query
@mixin media-section-stack(
    $gutter: map-fetch($media-spacing, null),
    $selector: ".o-media__section"
) {
  flex-wrap: wrap;

  > #{$selector} {
    flex-basis: 100%;
    max-width: 100%;
    padding: 0;

    img {
      max-width: auto;
    }

    &:first-child {
      padding-right: 0;
    }

    &:last-child:not(:nth-child(2)) {
      padding-left: 0;
    }

    + #{$selector} {
      padding-top: $gutter;
    }
  }
}

.o-media {
  @include media-container;
}

.o-media__section {
  @include media-section;
}

// Size modifiers
// ====================================

// Loop through the settings map and generate spacing modifier classes
@each $media-modifier, $media-value in $media-spacing {
  .o-media#{$media-modifier} {
    @include media-size($media-value);
  }
}

// Alignement modifiers
// ====================================

.o-media--middle {
  align-items: center;
}

.o-media--bottom {
  align-items: flex-end;
}
