@include generate-responsive(".u-position-absolute") {
  position: absolute !important;
}

@include generate-responsive(".u-position-relative") {
  position: relative !important;
}

@include generate-responsive(".u-position-static") {
  position: static !important;
}

@include generate-responsive(".u-position-fixed") {
  position: fixed !important;
}
