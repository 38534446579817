@mixin fluid-font(
  $font-size,
  $min-width: sm,
  $max-width: lg,
  $important: false
) {
  $flag: null;

  @if $important {
    $flag: "!important";
  }

  @if length($font-size) == 2 {
    $min-font: nth($font-size, 1);
    $max-font: nth($font-size, 2);

    @if map-has-key($mq-breakpoints, $min-width) {
      $min-width: map-get($mq-breakpoints, $min-width);
    }

    @if map-has-key($mq-breakpoints, $max-width) {
      $max-width: map-get($mq-breakpoints, $max-width);
    }

    font-size: $min-font #{$flag};

    @include mq($min-width) {
      font-size: calc( #{$min-font} + #{strip-unit($max-font - $min-font)} * ((100vw - #{$min-width}) / #{strip-unit($max-width - $min-width)}) ) #{$flag};
    }


    @include mq($max-width) {
      font-size: $max-font #{$flag};
    }
  }

  @else if length($font-size) == 1 {
    font-size: $font-size #{$flag};
  }
}
