@mixin generate-responsive(
  $selector: null,
  $respond-to: "all",
  $map: $mq-breakpoints
) {
  $_responsive-alias: null !global;

  @if $selector {
    #{$selector} {
      @content;
    }
  }

  @else {
    @content;
  }

  @if $respond-to {

    @if $respond-to == "all" {
      $respond-to: map-keys($map);
    }

    @each $breakpoint in $respond-to {
      @if map-has-key($map, $breakpoint) {
        $_responsive-alias: \@#{$breakpoint} !global;

        @include mq($breakpoint, $breakpoints: $map) {
          @if $selector {
            #{$selector}#{$_responsive-alias} {
              @content;
            }
          }

          @else {
            @content;
          }
        }
      }

      @else {
        @warn "Breakpoint '#{$breakpoint}' does not exist in '$map'";
      }

      $_responsive-alias: null !global;
    }
  }
}
