.c-section-wrap {
  overflow: hidden;
  position: relative;
}

.c-section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.c-section--center {
  justify-content: center;
}

.c-section--bottom {
  justify-content: flex-end;
}

.c-section__inner {
  padding-bottom: $section-anchor-height;

  .c-section--center & {
    padding-bottom: $site-nav-height--mobile + spacer(1);

    @include mq(md) {
      padding-bottom: $site-nav-height + spacer(1);
    }
  }

  .c-section--center &,
  .c-section--bottom & {
    padding-top: $site-nav-height--mobile + spacer(1);

    @include mq(md) {
      padding-top: $site-nav-height + spacer(1);
    }
  }
}

.c-section__content {
  @include remove-last-child-margin;
  color: #fff;
  padding-top: $site-nav-height + spacer(1);
  padding-bottom: 50px;
  background-color: rgba(#000, 0.65);

  .c-section--center &,
  .c-section--bottom & {
    padding-top: 50px;
  }

  .c-section--white & {
    background-color: rgba(#fff, 0.85);
  }
}

.c-section__anchor {
  @include fluid-font(14px 26px);
  @include font-family($weight: bold);
  display: flex;
  text-transform: lowercase;
  width: 100%;
  height: $section-anchor-height;
  background: #333;
  color: #fff;
  margin-top: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  align-items: center;
}

.c-section__anchor-inner {
  flex: 1;
  line-height: 1.1;

  @include mq($until: sm) {
    max-width: 45%;
    margin: 0;
  }
}

.c-section__anchor-arrow {
  @include fluid-font(20px 28px);
  display: block;
  width: 1em;
  height: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -0.5em;
  margin-top: -0.8em;
  transform: rotate(45deg);
  border-right: solid 3px #fff;
  border-bottom: solid 3px #fff;
}

.c-section__bg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: {
    size: cover;
    position: center;
  }
}

// Our model section

.c-section--model {
  @include mq($until: $section-bp) {
    min-height: auto;
  }

  .c-section__inner {
    @include mq($section-bp) {
      background-color: transparent !important;
    }
  }

  .c-section__content {
    @include mq($section-bp) {
      max-width: 470px;
    }
  }
}

.c-sectiom--model__img {
  display: block;
  width: 100%;

  @include mq($section-bp) {
    display: none;
  }
}

.c-section__bg--model {
  background-image: none;

  @include mq($section-bp) {
    background-position: right center;
  }
}
