.c-content-slider {
  padding-left: 40px;
  padding-right: 40px;
  position: relative;

  @include mq(sm) {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.c-content-slider__arrow {
  @include fluid-font(35px 50px);
  top: 50%;
  position: absolute;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  color: #000;
  z-index: z(10);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mq($until: md) {
    display: none;
  }

  &:before {
    content: "";
    width: 0.5em;
    height: 0.5em;
    border-top: solid 3px currentColor;
    border-right: solid 3px currentColor;
    display: block;
  }

  &--prev {
    left: 0;

    &:before {
      transform: rotate(-135deg);
      margin-right: -0.25em;
    }
  }

  &--next {
    right: 0;

    &:before {
      transform: rotate(45deg);
      margin-left: -0.25em;
    }
  }
}
