html {
  @include fluid-font(16px 19px);
  @include font-family($family: base, $weight: regular);
  line-height: map-fetch($global-font, line-height, base);
  min-height: 100%;
  color: color(page, text);
  background: #222;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  &.is-locked {
    body {
      overflow: hidden !important;
    }
  }
}

body {
  &:after {
    content: "xs";
    display: none;
  }
  @each $bp, $bp-value in $global-breakpoints {
    @include mq($bp) {
      &:after {
        content: "#{$bp}";
      }
    }
  }
}

p:empty {
  display: none;
}