.c-title-banner {
  @include fluid-font(38px 62px);
}

.c-title-banner,
.c-lead {
  br {
    @include mq($until: lg) {
      display: none;
    }
  }
}

.c-lead {
  @include fluid-font(16px 19px);
}

.c-title-section {
  @include fluid-font(30px 57px);
  line-break: 1.2;

  @include mq(md) {
    margin-bottom: 50px;
  }
}

.c-title {
  @include fluid-font(40px 62px);
  line-height: 1;
}

@mixin title-sub {
  font-size: 14px;
  text-transform: uppercase;
  @include font-family($weight: black);
}

.c-tite-sub {
  @include title-sub();
}

@mixin bullet-list {
  font-size: 16px;
  margin-left: 0;
  margin-bottom: 0;

  li {
    display: block;
    position: relative;
    padding-left: 1em;
    font-weight: 700;

    &:before {
      content: "+";
      position: absolute;
      left: 0;
      top: 0;
    }

    + li {
      margin-top: spacer(0.5);
    }
  }

  ul {
    margin-left: 0;

    li {
      font-size: 14px;
      font-weight: normal;

      &:before {
        content: "•";
      }

      + li {
        margin-top: 0;
      }
    }
  }
}

.c-bullet-list {
  @include bullet-list();
}
