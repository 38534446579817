// Apply on-event styles in one go.

@mixin on-event($self: false) {
  @if $self != false {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }

  @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}